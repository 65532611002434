// Footer
.footer-comp {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100px;
  padding: 26px 0px;
  .wrap {
    @include media("<=desktop") {
      flex-direction: column;
      gap: 12px;
    }
    .left-side {
      flex: 1;
      @include media("<=desktop") {
        width: 100%;
      }
      .copy-right {
        @include media("<=phoneH") {
          font-size: 14px;
          &:nth-child(2) {
            margin: 0;
          }
        }
      }
    }
    .center-side {
      flex: 1;
      .pp-lbl {
        cursor: pointer;
        &:hover {
          color: #ae2744;
        }
      }
    }
    .right-side {
      flex: 1;
      justify-content: end;
      .social-icons {
        .icon {
          margin-left: 20px;
          @include anim;
          svg {
            height: 28px;
            width: 28px;
            path {
              stroke: #fff;
            }
          }
          &:hover {
            svg {
              height: 28px;
              width: 28px;
              path {
                stroke: #ae2744;
              }
            }
          }
        }
      }
    }
  }
}
