//Drop Down
.dropDown {
  .category {
    .box {
      background: #fff;
      border: 1px solid #dddd;
      padding: 0 16px;
      min-height: 40px;
      border-radius: 8px;
      box-sizing: border-box;
      min-width: 180px;
      justify-content: space-between;
      @include media(">=phone", "<=480px") {
        min-width: 150px;
      }
      .slt {
        overflow: hidden;
        .lbl {
          font-size: 18px;
          font-weight: 500;
          color: $black;
          padding: 0 10px;
        }
      }
      .arrow {
      }
    }
  }
  .block {
    top: 45px;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    .manue {
      width: 100%;
      height: 150px;
      overflow-y: auto;
      padding: 5px 0px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      .slt {
        width: 100%;
        padding: 8px 16px;
        .lbl {
          flex: 1;
          font-size: 16px;
          font-weight: 500;
          margin-left: 5px;
          justify-content: flex-start;
        }
        &:hover {
          cursor: pointer;
          background: $themeColor;
          .lbl {
            color: #ffffff;
          }
        }
      }
    }
    &.show {
      display: flex;
      visibility: visible;
      opacity: 1;
      @include dropDownAnim;
    }
  }
}
