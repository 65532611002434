.generate-url-summary {
  height: 550px;
  width: 550px;
  background: $themeColor;
  overflow: hidden;
  overflow-y: auto;
  .generate-url-summary-block {
    width: 100%;
    padding: 30px;

    .block-hdr {
      margin-bottom: 12px;
      .icon {
        svg {
          &:hover {
            path {
              stroke: $pinkDark;
            }
          }
        }
      }
    }
    .field {
      &:last-child {
        padding-bottom: 40px;
      }
      .lbl {
        font-size: 24px;
        font-weight: 500;
        line-height: 22px;
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 10px;
      }
      .txt-area {
        width: 100%;
        padding: 5px 6px;
        border-radius: 4px;
        font-weight: 500;
        border: 1.8px solid #cccccc;
      }
      .btn {
        font-size: 18px;
        font-weight: 500;
        margin-top: 12px;
        background: $pinkDark;
      }
    }
  }
}
