.update-headline-model {
  min-height: 200px;
  width: 350px;
  background: $themeColor;

  .update-headline-m-block {
    width: 100%;
    padding: 30px;
    .model-hdr {
      .cross-icon {
        &:hover {
          svg {
            stroke: #ae2744;
          }
        }
      }
    }
    .field {
      .lbl {
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        color: $white;
        font-family: "Montserrat", sans-serif;
        margin-bottom: 10px;
      }
      .txt {
        width: 100%;
        padding: 5px 6px;
        border-radius: 4px;
        font-weight: 500;
        border: 1.8px solid #cccccc;
        &:focus {
          border-color: #ae2744;
        }
      }
      .btn {
        margin-top: 20px;
        background: $pinkDark;
      }
    }
  }
}
