.demo-video-model {
  min-height: 600px;
  width: 550px;
  background: $themeColor;

  @include media("<=phoneH") {
    width: 310px;
  }

  .demo-video-model-block {
    width: 100%;
    padding: 20px;
    .block-hdr {
      margin-bottom: 12px;
      .icon {
        svg {
          &:hover {
            path {
              stroke: $pinkDark;
            }
          }
        }
      }
    }
    .sec-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: #fff;
      font-family: "Montserrat", sans-serif;
      text-align: center;
      @include media("<=phoneH") {
        font-size: 14px;
      }
    }
    .video-block {
      height: 100%;
      width: 100%;
    }
    .btn-download-file {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      background: #ae2744;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #fff;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      border-radius: 8px;
      text-align: center;
      width: 100%;
      @include media("<=phoneH") {
        font-size: 12px;
        padding: 8px;
        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}
