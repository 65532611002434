// Header
.header-camp {
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 90px;
  padding: 12px 0px;
  background: #ffffff;
  .wrap {
    @include media("<=phoneH") {
      flex-direction: column;
      gap: 15px;
    }
    .menu-list {
      .menu-item {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        color: #ae2744;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        margin-right: 30px;
        cursor: pointer;
        @include anim;
        &:hover {
          color: #ed6270;
        }
        @include media("<=phoneH") {
          font-size: 14px;
        }
      }
    }
    .left {
      flex: 1;
      @include media("<=tablet") {
        flex-direction: column;
        align-items: start;
        width: 100%;
      }
      .logo-img {
        .logo {
          height: 60px;
        }
        @include media("<=phoneH") {
          .logo {
            height: 24px;
          }
        }
      }
      .demo-limit {
        @include media("<=phoneH") {
          width: 100%;
          justify-content: space-between;
        }
        .limit-lbl {
          font-size: 18px;
          font-weight: 600;
          line-height: 20px;
          color: #ae2744;
          text-transform: uppercase;
          font-family: "Montserrat", sans-serif;
          margin-right: 10px;
          @include media("<=phoneH") {
            font-size: 14px;
          }
        }
        .limit-val {
          color: #133250;
          padding: 8px;
          border-radius: 6px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          text-transform: uppercase;
          font-family: "Montserrat", sans-serif;
          border: 1px solid $themeColor;
        }
      }
    }
    .right {
      flex: 1;
      @include media("<=tablet") {
        flex-direction: column-reverse;
        align-items: end;
        justify-content: center;
        height: 100%;
      }
      @include media("<=phoneH") {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      .logout-action {
        margin-left: 20px;
        @include media("<=tablet") {
          margin-bottom: 20px;
        }
        @include media("<=phoneH") {
          margin-left: 0px;
          margin-bottom: 0;
          align-items: center;
        }
        .btn {
          background: #133250;
          border: 1px #133250 solid;
          color: #fff;
          display: table;
          text-align: center;
          cursor: pointer;
          text-align: center;
          padding: 8px 20px;
          font-size: 18px;
          font-weight: 500;
          cursor: pointer;
          @include media("<=phoneH") {
            font-size: 14px;
            padding: 6px 8px;
          }
        }
      }
    }
  }
}
