.tbl-row-edit {
  min-height: 200px;
  width: 660px;
  background: $themeColor;
  .model-block {
    width: 100%;
    padding: 30px;
    .model-hdr {
      .cross-icon {
        &:hover {
          svg {
            stroke: #ae2744;
          }
        }
      }
    }
    .input-field {
      .input-tag {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 6px;
        flex: 0.3;
      }
      .model-val {
        flex: 1;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        padding: 12px 8px;
      }
    }
    .btn-update {
      font-size: 20px;
      font-weight: 600;
      border: 2px solid #ae2744;
      border-radius: 8px;
      background: $white;
      color: #ae2744;
    }
  }
}
