// @import url(https://allfont.net/allfont.css?fonts=agency-fb);
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./Colors.scss";
@import "./Props.scss";
@import "./media.scss";
@import "../components/Header/style.scss";
@import "../components/Footer/style.scss";
@import "../components/Sidebar/style.scss";
@import "../Pages/Home/style.scss";
@import "../Pages/Login/style.scss";
@import "../Pages/TermsCondition/terms-condition.scss";
@import "../components/TableRowModel/tableRowModel.scss";
@import "../components/UpdateHeadlineModel/updateHeadlineModel.scss";
@import "../components/GenerateUrlSummaryModel/generateurl.scss";
@import "../components/DropDown/dropDown-style.scss";
@import "../components/VideoModel/video-style.scss";
@import "../components/WoFileRow/EditRow/editRow.scss";

.font,
body {
  // font-family: "Agency FB", "Montserrat";
  font-family: "Montserrat", sans-serif;
}
.font-m {
  font-family: "Montserrat", sans-serif;
}

@mixin bgImage($radius: 50%) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #e2e2e2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

@mixin dropDownAnim($duration: 0.3s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}

@keyframes drop {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
}

.wrapWidth {
  width: 1280px;
  margin: 0 auto;
  @include media("<=xlarge") {
    width: 1180px;
    margin: 0 auto;
  }
  @include media("<=large") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=desktop") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=tablet") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media(">=phone", "<=480px") {
    padding: 0px 5%;
  }
}

.App {
  min-height: 100vh;
}

.register-page {
  .inner-box {
    @include media("<=desktop") {
    }
  }
}

//Radio Button
.radio-btn {
  height: 22px;
  width: 22px;
  min-width: 22px;
  border: 2px solid #ae2744;
  border-radius: 50%;
  margin-right: 12px;
  &::before {
    content: "";
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    min-width: 14px;
    background: $themeColor;
    border-radius: 50%;
    left: 2px;
    top: 2px;
    visibility: hidden;
    opacity: 0;
    @include anim;
  }
  &.on {
    border-color: $themeColor;
    &:before {
      visibility: visible;
      opacity: 1;
    }
  }
}

// Toggle
.toggle-btn {
  flex: 0.4;
  .btn {
    display: flex !important;
    border-radius: 50px;
    padding: 10px 22px !important;
    background: #14192d;
    opacity: 0.4;
    border-color: none;
    .circle {
      left: 2px;
      // margin-bottom: 1px;
      padding: 8px;
      background: #fff;
      border-radius: 50%;
    }
    &.on {
      background: #ae2744;
      opacity: 1;
      .circle {
        left: 25px;
        // background: $themeColor !important;
      }
    }
  }
}

// Dashboard
.dashboard-page {
  min-height: 730px;
  .wrap {
  }
}

.Toastify__toast-container {
  min-width: max-content;
}

// Loader
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-cmp {
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, 0.65);
  z-index: 9999999999;
  overflow: hidden;
  position: absolute;
  position: fixed;
  inset: 0;
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid $themeColor;
      border-color: $themeColor transparent $themeColor transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}

.row2 {
  grid-gap: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.row3 {
  grid-gap: 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
