.terms-condition-page {
  padding: 30px 0;
  .wrap {
    .page-title {
      text-align: center;
      color: $white;
      font-size: 26px;
      font-weight: 500;
    }
  }
}

.terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  font-size: 18px;
}

h1 {
  color: $white;
  font-size: 36px;
  text-align: center;
  margin-bottom: 40px;
}

h2 {
  color: $white;
  font-size: 28px;
  margin-bottom: 20px;
}

p {
  color: $white;
  margin-bottom: 20px;
}

ol {
  color: $white;
  margin-bottom: 40px;
}

ol li {
  color: $white;
  margin-bottom: 40px;
}

ol li ol {
  color: $white;
  margin-bottom: 20px;
}
