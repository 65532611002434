.lading-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  .wrap {
    .page-title {
      margin-bottom: 30px;
      padding: 40px 20px;
      border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      @include media("<=phoneH") {
        padding: 6px 20px;
        margin-bottom: 0px;
      }
      .page-title-tag {
        color: $themeColor;
        font-size: 24px;
        font-weight: 600;
        line-height: 22px;
        font-family: "Montserrat", sans-serif;
        @include media("<=phoneH") {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .select {
      min-width: 160px;
      .css-1okebmr-indicatorSeparator {
        display: none;
      }
    }
    .page-tabs {
      margin: 30px 0;
      border-radius: 8px;
      background: #ffffff;
      height: 100px;
      width: 100%;
      overflow: hidden;
      .tab-item {
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 12px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        @include anim;
        border-right: 1px solid #133250;
        .tab-item-desc {
          font-size: 14px;
          font-weight: 400;
        }
        &.active {
          background: #133250;
          color: #ffffff;
        }
        &:last-child {
          border-right: none;
          color: #eee;
        }
      }
    }
    .page-header {
      margin: 30px 0;
      padding: 40px 20px;
      background: #ffffff;
      border-radius: 8px;
      @include media("<=phoneH") {
        padding: 20px;
      }
      .row {
        @include media("<=phoneH") {
          width: 100%;
        }
        .tag {
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          color: #ae2744;
          font-family: "Montserrat", sans-serif;
        }
        .select-file {
          margin: 20px 0;
        }
        .btn {
          font-size: 18px;
          font-weight: 500;
          width: 200px;
        }
        .btn-download {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          width: 34px;
          height: 34px;
          padding: 4px;
          svg {
            height: 20px;
            width: 20px;
            path {
            }
          }
        }
      }
      .file-action {
        width: 100%;
        grid-gap: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        .css-1s2u09g-control,
        .css-1pahdxg-control {
          padding: 6px !important;
        }
        .btn {
          width: 100%;

          @include media("<=phoneH") {
            width: 100%;
            &:first-child {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    .page_block {
      padding: 40px 20px;
      background: #ffffff;
      border-radius: 8px;
      .block-tag {
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        color: #ae2744;
        font-family: "Montserrat", sans-serif;
        // margin-bottom: 30px;
      }
      .block_filter {
        gap: 30px;
        .field {
          flex: 1;
          .f-lbl {
            font-size: 16px;
            font-weight: 500;
            font-family: "Montserrat", sans-serif;
            // font-family: "Agency FB", arial;
            margin-bottom: 5px;
          }
          .txt {
            padding: 5px 6px;
            border-radius: 4px;
            font-weight: 500;
            border: 1.8px solid #cccccc;
            &:focus {
              border-color: #2684ff;
            }
          }

          .search-box {
            &:focus-within {
              border-color: #2684ff;
            }
            .icon {
              svg {
                height: 18px;
              }
            }
            .txt-s {
              font-size: 18px;
              padding: 0 10px;
              width: 100%;
            }
          }
          .action {
            .btn {
              padding: 8px 18px;
            }
          }
        }
      }
    }
    .table-section {
      margin: 30px 0;
      .tbl-sec-hdr {
        margin-bottom: 30px;
        @include media("<=phoneH") {
          flex-direction: column;
          width: 100%;
          gap: 20px;
          align-items: start;
        }
        .table-name {
          margin-bottom: 12px;
          font-size: 24px;
          font-weight: 600;
          color: #ae2744;
        }
        .dowload-btn {
          @include media("<=phoneH") {
            width: 100%;
            a {
              width: 100%;
            }
          }
          .btn {
            font-size: 18px;
            font-weight: 500;
            @include media("<=phoneH") {
              width: 100%;
            }
          }
        }
      }
    }
    .global-row {
      width: 100%;
      padding: 8px 12px;
      border: 2px solid $themeColor;
      margin-bottom: 12px;
      @include media("<=desktop") {
        min-width: 1200px;
      }
      &.expend {
        min-height: 200px;
        border-radius: 10px;
      }
      .global-row-box {
        .row-item {
          flex: 1;
          padding: 0 12px;
          height: 100%;
          &:first-child {
            flex: 2;
          }
          &:last-child {
            flex: 0;
          }
          .tag-lbl {
            font-size: 18px;
            font-weight: 600;
            color: #ae2744;
            margin-right: 8px;
          }
          .toggle-desc {
            display: flex;
            color: #63697d;
          }
        }
      }
      .global-row-expend-block {
        align-items: center;
        margin-top: 30px;
        margin-bottom: 20px;
        .expend-row-headline {
          width: 100%;
          padding: 0 4%;
          .expend-row-headline-top {
            width: 100%;
            gap: 30px;
            &:first-child {
              flex: 0.5;
            }

            .txt-seach-key {
              // width: 100%;
              padding: 5px 6px;
              border-radius: 4px;
              font-weight: 500;
              border: 1.8px solid #cccccc;
              &:focus {
                border-color: #2684ff;
              }
            }
            .btn {
              width: 300px;
              font-size: 14px;
              font-weight: 500;
              padding: 8px 12px;
            }
          }
          .categories-block {
            margin-top: 40px;
            width: 100%;
            grid-gap: 30px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            .category-box {
              height: 140px;
              width: 100%;
              padding: 8px;
              border-radius: 10px;
              border: 2px solid #ae2744;
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              .category-name {
                font-size: 18px;
                font-weight: 600;
              }
              .category-numb {
                margin: 10px 0;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 5px;
              }
              .btn-idea {
                font-size: 14px;
                font-weight: 500;
                border-radius: 6px;
                background: #133250;
                border: 1px solid #133250;
                color: #fff;
                display: table;
                text-align: center;
                padding: 8px 12px;
                cursor: pointer;
              }
            }
          }
          .expend-tag {
            margin: 40px 0;
            font-size: 34px;
            font-weight: 600;
            color: #ae2744;
          }
        }
        .expend-table-section {
          width: 100%;
          padding: 0 4%;
          .exp-row {
            margin-bottom: 12px;
            border-bottom: 1px solid #63697d;
            padding-bottom: 6px;
            &:last-child {
              margin-bottom: 0;
            }
            .exp-row-item {
              flex: 0.2;
              &:first-child {
                flex: 0;
                .col-numb {
                  width: 30px;
                }
                .check-box {
                }
                .item-status {
                }
              }
              &:nth-child(2) {
                flex: 0.1;
              }
              &:nth-child(3) {
                flex: 1;
              }
              .edit-icon {
                &:hover {
                  svg {
                    path {
                      stroke: #ae2744;
                    }
                  }
                }
              }
              .btn {
                font-size: 12px;
                font-weight: 500;
                padding: 6px 8px;
              }
            }
          }
        }
      }
    }
    .table_block {
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 6px;
      }
      .main-table-column {
        .tbl-column {
          width: 100%;
          padding: 2px 12px;
          // border-bottom: 2px solid $themeColor;
          font-size: 22px;
          font-weight: 600;
          color: #ae2744;
          .row-item {
            padding: 0 12px;
            height: 100%;

            h1 {
              margin-bottom: 0;
            }
            &:first-child {
              flex: 2;
              padding: 0;
            }
            &:nth-child(2) {
              flex: 2;
            }
            &:nth-child(3) {
              flex: 1.5;
            }
            &:last-child {
              flex: 0.5 !important;
              padding: 0;
            }
          }
        }
      }
      .main-table-row {
        width: 100%;
        padding: 8px 12px;
        border: 2px solid $themeColor;
        margin-bottom: 12px;
        @include media("<=desktop") {
          min-width: 1200px;
        }
        &.expend {
          min-height: 200px;
          border-radius: 10px;
        }
        .tbl-row {
          &:last-child {
            margin-bottom: 0;
          }
          .row-item {
            // flex: 1;
            padding: 0 12px;
            height: 100%;
            h1 {
              margin-bottom: 0;
            }
            &:first-child {
              flex: 2;
              padding: 0;
            }
            &:nth-child(2) {
              flex: 2;
            }
            &:nth-child(3) {
              flex: 1.5;
            }
            &:last-child {
              flex: 0.5 !important;
              padding: 0;
            }
            .tag-lbl {
              font-size: 18px;
              font-weight: 600;
              color: #ae2744;
              margin-right: 8px;
            }
            .tag {
              font-size: 16px;
              font-weight: 500;
            }
            .desc {
              color: #333333;
            }
            .btn {
              font-size: 14px;
              padding: 6px 12px;
            }
            .toggle-desc {
              display: flex;
              color: #63697d;
            }
            .item {
              margin-bottom: 8px;
            }
            .icons-actions {
              .icon {
                margin-right: 12px;
                &:last-child {
                  margin-right: 0;
                }
              }
              .cloud-icon {
                @include anim;
                &:hover {
                  svg {
                    path {
                      stroke: #ae2744;
                    }
                  }
                }
              }
              .detail-icon {
                cursor: pointer;
                @include anim;
                svg {
                  height: 18px;
                  width: 18px;
                }
                &:hover {
                  svg {
                    path {
                      fill: #ae2744;
                    }
                  }
                }
              }
              .arrow-icon {
                cursor: pointer;
                @include anim;
                svg {
                  height: 34px;
                  width: 34px;
                }
                &:hover {
                  svg {
                    path {
                      stroke: #ae2744;
                    }
                  }
                }
              }
            }
          }
        }
        .row-expend-block {
          align-items: center;
          margin-top: 30px;
          margin-bottom: 20px;
          .expend-row-headline {
            width: 100%;
            padding: 0 4%;
            // grid-gap: 30px;
            // display: grid;
            // grid-template-columns: repeat(4, 1fr);

            .expend-row-headline-top {
              width: 100%;
              gap: 30px;
              &:first-child {
                flex: 0.5;
              }

              .txt-seach-key {
                // width: 100%;
                padding: 5px 6px;
                border-radius: 4px;
                font-weight: 500;
                overflow-y: auto;
                border: 1.8px solid #cccccc;
                &:focus {
                  border-color: #2684ff;
                }
              }
              .btn {
                width: 300px;
                font-size: 14px;
                font-weight: 500;
                padding: 8px 12px;
              }
            }
            .categories-block {
              margin-top: 40px;
              width: 100%;
              grid-gap: 30px;
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              .category-box {
                height: 140px;
                width: 100%;
                padding: 8px;
                border-radius: 10px;
                border: 2px solid #ae2744;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                .category-name {
                  font-size: 18px;
                  font-weight: 600;
                }
                .category-numb {
                  margin: 10px 0;
                  font-size: 18px;
                  font-weight: 500;
                  letter-spacing: 5px;
                }
                .btn-idea {
                  font-size: 14px;
                  font-weight: 500;
                  border-radius: 6px;
                  background: #133250;
                  border: 1px solid #133250;
                  color: #fff;
                  display: table;
                  text-align: center;
                  padding: 8px 12px;
                  cursor: pointer;
                }
              }
            }
            .expend-tag {
              margin: 40px 0;
              font-size: 34px;
              font-weight: 600;
              color: #ae2744;
            }
          }
          .expend-table-section {
            width: 100%;
            padding: 0 4%;
            .exp-row {
              margin-bottom: 12px;
              border-bottom: 1px solid #63697d;
              padding-bottom: 6px;
              &:last-child {
                margin-bottom: 0;
              }
              .exp-row-item {
                flex: 1;
                &:first-child {
                  flex: 0;
                  .col-numb {
                    width: 30px;
                  }
                  .check-box {
                  }
                  .item-status {
                  }
                }
                &:nth-child(2) {
                  flex: 0.1;
                }
                &:nth-child(3) {
                  flex: 1.1;
                }
                &:nth-child(4) {
                  flex: 0.3;
                }
                &:last-child {
                  flex: 0.1;
                }
                .eye-icon {
                  &:hover {
                    svg {
                      path {
                        fill: #ae2744;
                      }
                    }
                  }
                }
                .edit-icon {
                  &:hover {
                    svg {
                      path {
                        stroke: #ae2744;
                      }
                    }
                  }
                }
                .exp-row-tag {
                  font-size: 14px;
                  font-weight: 500;
                }
                .item-status {
                  padding: 3px 12px;
                  border: 1px solid #ae2744;
                  border-radius: 30px;
                }
                .txt-seach-key {
                  padding: 5px 6px;
                  border-radius: 4px;
                  font-weight: 500;
                  border: 1.8px solid #cccccc;
                  &:focus {
                    border-color: #2684ff;
                  }
                }
                .btn {
                  font-size: 12px;
                  font-weight: 500;
                  padding: 6px 8px;
                }
              }
            }
          }
        }
        &:first-child {
          border-top: none !important;
          border-right: none !important;
          border-left: none !important;
          border-bottom: 2px solid #133250 !important;
        }
      }
    }
  }
}
